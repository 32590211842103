import React from "react";
import { CustomIconProps } from "./add-01-solid-standard";

const FilterMailCircleIcon: React.FC<CustomIconProps> = ({
  size = 24,
  color = "#000000",
  ...props
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width={size}
    height={size}
    color={color}
    fill={"none"}
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 22.75C6.06294 22.75 1.25 17.9371 1.25 12C1.25 6.06294 6.06294 1.25 12 1.25C17.9371 1.25 22.75 6.06294 22.75 12C22.75 17.9371 17.9371 22.75 12 22.75ZM8.50001 11.5C7.94772 11.5 7.50001 11.9477 7.50001 12.5C7.50001 13.0523 7.94772 13.5 8.50001 13.5L15.5 13.5C16.0523 13.5 16.5 13.0523 16.5 12.5C16.5 11.9477 16.0523 11.5 15.5 11.5H8.50001ZM9.5 16C9.5 15.4477 9.94771 15 10.5 15H13.5C14.0523 15 14.5 15.4477 14.5 16C14.5 16.5523 14.0523 17 13.5 17H10.5C9.94771 17 9.5 16.5523 9.5 16ZM7.5 8C6.94772 8 6.5 8.44772 6.5 9C6.5 9.55229 6.94772 10 7.5 10H16.5C17.0523 10 17.5 9.55229 17.5 9C17.5 8.44772 17.0523 8 16.5 8H7.5Z"
      fill="currentColor"
    />
  </svg>
);

export default FilterMailCircleIcon;
