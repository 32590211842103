import { useEffect, useState } from "react";
import { SubscriptionPlan } from "../classes/SubscriptionPlan";
import SubscriptionPlanCard from "./ui/SubscriptionPlanCard";
import { HorseAd } from "../classes/HorseAd";
import { getDocsFromFirestoreWithSort } from "../services/firebase";
import { CircularProgress } from "@mui/material";

interface SelectSubsciptionPlanProps {
  selectedHorseId: string;
  setSelectedPlan: (plan: SubscriptionPlan) => void;
}

export const SelectSubsciptionPlan: React.FC<SelectSubsciptionPlanProps> = ({
  selectedHorseId,
  setSelectedPlan,
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const [subscriptionPlans, setSubscriptionPlans] = useState<
    SubscriptionPlan[]
  >([]);

  const isActivePlan = (plan: SubscriptionPlan): boolean => {
    return parseInt(plan.state) === 1;
  };

  const fetchAndProcessPlans = async () => {
    try {
      const res = await getDocsFromFirestoreWithSort(
        "SubscriptionPlans",
        "createdTime",
        "desc"
      );
      const docs = res.docs;

      // console.log("Subscription Plans:", docs.length);

      // Filter the plans with valid data
      const fetchedPlans = docs.map((doc) => doc.data() as SubscriptionPlan);

      // Filter active plans
      const activePlans = fetchedPlans.filter((plan) => isActivePlan(plan));

      setSubscriptionPlans(activePlans);
    } catch (error) {
      console.error("Error fetching ads:", error);
    }
  };

  useEffect(() => {
    fetchAndProcessPlans();
  }, []);

  return (
    <div>
      <div className="flex justify-center items-start py-4 md:p-6 ">
        <h2 className="text-4xl w-full text-center font-bold">
          Kies Je Pakket
        </h2>
      </div>

      <h1 className="font-semibold text-xl text-center opacity-90">
        Je paardgegevens zijn succesvol opgeslagen, kies hieronder het
        abonnementsplan om de advertentie aan de wereld te tonen.
      </h1>

      {isLoading ? (
        <div className="fixed inset-0 flex items-center justify-center bg-opacity-50 bg-gray-800">
          <CircularProgress />
        </div>
      ) : (
        <></>
      )}

      {subscriptionPlans.length !== 0 ? (
        <div>
          {subscriptionPlans.map((plan: SubscriptionPlan, index: number) => (
            <SubscriptionPlanCard
              key={index}
              plan={plan}
              setSelectedPlan={setSelectedPlan}
            />
          ))}
        </div>
      ) : (
        <p className="text-center">
          Even geduld, de nieuwste advertenties worden geladen ...
        </p>
      )}
    </div>
  );
};
