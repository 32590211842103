import { addDoc, collection, doc, setDoc, updateDoc } from "firebase/firestore"; // Import Firestore methods
import { firestore, ROOT_NODE } from "./firebase";
import { useContext } from "react";
import { GlobalContext } from "../contexts/GlobalContext";

interface TransactionData {
  [key: string]: any;
}

interface CouponCode {
  id?: string;
  code?: string;
}

const PAYMENT_TRANSACTION_ROOT: string = "PayNl";

export const makeNewPaymentTransaction = async (
  transactionData: any,
  myPlanId: string,
  myPlanName: string,
  myCost: number,
  myDuration: number,
  startDate: number = 0,
  endDate: number = 0,
  gatewayTransactionId: string,
  horseId: string,
  duration: string,
  //   serviceId: string = "",
  //   expertId: string = "",
  userId: string,
  selectedCouponCode?: CouponCode
) => {
  try {
    const paymentRequestsPath: string =
      PAYMENT_TRANSACTION_ROOT + "/" + userId + "/PaymentRequests/";

    // Create a new document reference
    const newTransactionObj = collection(firestore, paymentRequestsPath);

    // console.log("MakeNewPaymentTransaction TRN ID: ", newTransactionObj);

    // Transaction data to save
    const transData = {
      id: "",
      createdTime: Date.now(),
      planId: myPlanId,
      cost: myCost,
      duration: myDuration,
      planStartDate: startDate,
      planEndDate: endDate,
      gatewayTransactionId: gatewayTransactionId,
      horseId: horseId,
      userId: userId,
      paymentStatus: "Pending",
      state: "New",
      paymentGatewayResponse: transactionData,
      serviceId: "",
      expertId: "",
    };

    // console.log("MakeNewPaymentTransaction encoded as JSON", transData);
    // console.log("1. Writing transData ... ");

    // Write the transaction data to Firestore
    const newDocRef = await addDoc(newTransactionObj, transData);

    await updateDoc(newDocRef, { id: newDocRef.id });

    // console.log("MakeNewPaymentTransaction newDocRef ID: ", newDocRef.id);

    // console.log("🚀 New Transaction Written Successfully: ", newDocRef.id);

    // Get orderId from transactionData if available
    const orderId = transactionData["orderId"];
    if (!orderId) {
      return "";
    }

    // Set orderId for this transaction
    return await writeOrderData(
      newDocRef.id,
      orderId,
      horseId,
      duration,
      myPlanId,
      myPlanName,
      "",
      "",
      userId,
      selectedCouponCode
    );
  } catch (error) {
    console.error(
      "Error: MakeNewPaymentTransaction encoding as JSON Failed: ",
      error
    );
  }
  return "";
};

const writeOrderData = async (
  transId: string,
  orderId: string,
  horseId: string,
  duration: string,
  planId: string,
  planName: string,
  serviceId: string,
  expertId: string,
  userId: string,
  selectedCouponCode?: CouponCode
): Promise<string> => {
  try {
    // console.log("Order Id: ", orderId);

    // Order data to store in Firestore
    const orderData = {
      transactionId: transId,
      userId: userId,
      horseId: horseId,
      orderId: orderId,
      planId: planId,
      planName: planName,
      duration: duration,
      horseCollection: ROOT_NODE,
      serviceId: serviceId,
      expertId: expertId,
      couponCodeId: selectedCouponCode?.id || "",
      couponCodeString: selectedCouponCode?.code || "",
    };

    // Get the Firestore document reference
    const docRef = doc(firestore, "PaymentRequestPaths", orderId);

    // console.log("writeOrderData called & orderData: ", orderData);

    // console.log("2. Writing orderData ... ");

    // Write order data to Firestore
    await setDoc(docRef, orderData);
    return transId;
    // console.log("🚀 Order Data Written: ", docRef.path);
  } catch (error) {
    console.error("Error creating document: ", error);
    throw error;
  }
};
