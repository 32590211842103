import React from "react";
import { CustomIconProps } from "./add-01-solid-standard";

const Call02Icon: React.FC<CustomIconProps> = ({
  size = 24,
  color = "#000000",
  ...props
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width={size}
    height={size}
    color={color}
    fill={"none"}
    {...props}
  >
    <path
      d="M6.84029 2H2.98748C2.43481 2 1.9852 2.45041 2.00037 3.00287C2.32108 14.68 8.32 20.6789 19.9971 20.9996C20.5496 21.0148 21 20.5652 21 20.0125V16.1597C21 15.7642 20.7671 15.4059 20.4057 15.2453L17.0195 13.7403C16.6957 13.5964 16.3198 13.6344 16.0315 13.8404L13.4527 15.6824C13.1682 15.8856 12.7959 15.9257 12.4761 15.7844C9.39119 14.4214 8.5786 13.6088 7.21558 10.5239C7.07428 10.2041 7.11435 9.83184 7.31758 9.54733L9.15957 6.96854C9.36555 6.68016 9.40365 6.30433 9.25971 5.98048L7.75473 2.59427C7.59412 2.2329 7.23575 2 6.84029 2Z"
      fill="currentColor"
    />
  </svg>
);

export default Call02Icon;
