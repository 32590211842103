import { forwardRef, useEffect, useRef, useState } from "react";
import { CustomTextInput } from "./CustomTextInput";
import {
  CouponCode,
  getDiscountAmount,
  isStringApplicable,
  isValid,
} from "../../classes/CouponCode";
import {
  getAmountInEuro,
  SubscriptionPlan,
} from "../../classes/SubscriptionPlan";
import MuiAlert from "@mui/material/Alert";

import {
  getDocsFromFirestore,
  getDocsFromFirestoreWhereEquals,
  getDocsFromFirestoreWithSort,
} from "../../services/firebase";
import { UsedCouponCode } from "../../classes/UsedCouponCodes";
import { AlertProps, Snackbar } from "@mui/material";

const Alert = forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const snackBarConfigType: {
  open: boolean;
  severity: "success" | "error" | "warning" | "info";
  message: string;
} = { open: false, severity: "success", message: "" };

interface CouponCodeCardProps {
  selectedPlan: SubscriptionPlan;
  selectedHorseId: string;
  currentUserId: string;
  setFinalPayAbleAmount: (amount: number) => void;
  setSelectedCouponCode: (coupon: CouponCode | undefined) => void;
  // applyCouponCodeInOverview: (finalPrice: number) => void;
  // removeCouponCodeInOverview: () => void;
}

export const CouponCodeCard: React.FC<CouponCodeCardProps> = ({
  selectedPlan,
  selectedHorseId,
  currentUserId,
  setFinalPayAbleAmount,
  setSelectedCouponCode,
  // removeCouponCodeInOverview,
}) => {
  const couponCodeRef: React.RefObject<HTMLInputElement> = useRef(null);
  const [couponCode, setCouponCode] = useState<string | undefined>();
  const [activeCouponCodes, setActiveCouponCodes] = useState<CouponCode[]>([]);
  const [usedCouponCodes, setUsedCouponCodes] = useState<UsedCouponCode[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [couponApplied, setCouponApplied] = useState<boolean>(false);
  const [couponCodeTitle, setCouponCodeTitle] = useState<string>(
    "Geen kortingscode toegepast!"
  );
  const [savingsLabel, setSavingsLabel] = useState<string>("");

  const [snackBarConfig, setSnackBarConfig] = useState(snackBarConfigType);

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackBarConfig({ open: false, severity: "success", message: "" });
  };

  const fetchUsedCouponCodes = async () => {
    setIsLoading(true);

    try {
      const res = await getDocsFromFirestoreWhereEquals(
        "UsedCouponCodes",
        "user_id",
        currentUserId
      );
      const docs = res?.docs;

      if (!docs) {
        return;
      }

      // console.log("Used Coupon Codes Plans:", docs.length);

      // Filter the plans with valid data
      const fetchedUsedCouponCode = docs.map(
        (doc) => doc.data() as UsedCouponCode
      );

      setUsedCouponCodes(fetchedUsedCouponCode);

      fetchAndProcessCouponCodes();
    } catch (error) {
      console.error("Error fetching used coupon codes:", error);
    }
  };

  const fetchAndProcessCouponCodes = async () => {
    try {
      const res = await getDocsFromFirestore("CouponCodes");
      const docs = res?.docs;

      if (!docs) {
        return;
      }

      // console.log("CouponCodes:", docs.length);

      // Filter the plans with valid data
      const fetchedCouponCodes = docs.map((doc) => doc.data() as CouponCode);

      // Filter active CouponCode
      const activeCouponCodes = fetchedCouponCodes.filter((couponCode) =>
        isValid(couponCode, usedCouponCodes)
      );

      setActiveCouponCodes(activeCouponCodes);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching ads:", error);
    }
  };

  const findCouponByCode = (
    couponCodeStr: string,
    activeCouponCodes: CouponCode[]
  ): CouponCode | null => {
    for (const coupon of activeCouponCodes) {
      if (coupon.code.toLowerCase() === couponCodeStr.toLowerCase()) {
        return coupon;
      }
    }

    return null;
  };

  const checkStringForValidCouponCode = (
    searchStringRaw: string,
    activeCouponCodes: CouponCode[]
  ) => {
    const searchString = searchStringRaw.trim();

    console.log("COUPONCODE", `Search String: ${searchString}`);

    const couponCode = findCouponByCode(searchString, activeCouponCodes);

    if (!couponCode) {
      // Alert: Coupon Code not valid
      setSnackBarConfig({
        open: true,
        severity: "error",
        message: `Ongeldige couponcode!, De couponcode '${searchString}' is not valid.`,
      });

      console.log(`⚠️ COUPONCODE: Coupon Code: ${searchString} is not valid!`);
      return;
    }

    // Check if the coupon code is applicable to the current plan
    if (isStringApplicable(couponCode, selectedPlan.id)) {
      applyCouponCode(couponCode);
    } else {
      // Alert: Coupon Code not applicable to this plan
      console.log(
        `⚠️ COUPONCODE: Coupon Code: ${searchString} is niet van toepassing op dit plan!`
      );
      setSnackBarConfig({
        open: true,
        severity: "error",
        message: `Ongeldige couponcode!, De couponcode '${searchString}' is niet geldig voor dit plan.`,
      });
    }
  };

  const applyCouponCode = (couponCode: CouponCode) => {
    const discountedPrice = getDiscountAmount(
      couponCode,
      parseInt(selectedPlan.cost)
    );
    setCouponApplied(true);

    setSelectedCouponCode(couponCode);

    setFinalPayAbleAmount(parseInt(selectedPlan.cost) - discountedPrice);
    setCouponCodeTitle(`'${couponCode.code.toUpperCase()}' toegepast!`);

    const savingAmount = discountedPrice;
    setSavingsLabel(
      `🎉 Je hebt ${getAmountInEuro(savingAmount.toString())} bespaard!`
    );
  };

  const removeCouponCode = () => {
    // removeCouponCodeInOverview();
    setCouponApplied(false);

    setSelectedCouponCode(undefined);

    setFinalPayAbleAmount(parseInt(selectedPlan.cost));
    setCouponCodeTitle("Geen kortingscode toegepast!");
    setSavingsLabel("");
  };

  const handleApplyOnCLick = () => {
    const userEnteredCouponCode: string = couponCodeRef.current?.value ?? "";

    checkStringForValidCouponCode(userEnteredCouponCode, activeCouponCodes);
  };

  useEffect(() => {
    fetchUsedCouponCodes();
    setCouponApplied(false);
  }, []);

  return (
    <div className="flex flex-col bg-[#386163] md:bg-[#10393b] py-4 px-6 rounded-3xl ">
      <div className="flex items-center">
        <img className="w-8 h-8" src="/icons/discount-icon.svg" alt="" />
        <p className="text-lg md:text-2xl ml-3 text-center font-bold">
          {couponCodeTitle}
        </p>
      </div>

      {!couponApplied ? (
        <div className="flex flex-col justify-around items-center md:flex-row">
          <CustomTextInput
            isValid={true}
            label=""
            placeholder="Voer couponcode in"
            id=""
            name=""
            maxLength={30}
            inputRef={couponCodeRef}
            type="text"
            handleChange={() => setCouponCode(couponCodeRef.current?.value)}
            errorMessage=""
          />

          {isLoading ? (
            <img
              className="loading-anim"
              src="/icons/loading-anim.gif"
              alt=""
            />
          ) : (
            <p
              className="text-lg md:text-2xl mx-3 text-center font-bold text-[#f0663e] cursor-pointer"
              onClick={handleApplyOnCLick}
            >
              TOEPASSEN
            </p>
          )}
        </div>
      ) : (
        <></>
      )}

      {couponApplied ? (
        <div className="ml-4 h-16 flex items-center">
          <p className="text-lg md:text-2xl font-semibold text-[#26a835]">
            {savingsLabel}
          </p>
        </div>
      ) : (
        <></>
      )}
      {couponApplied ? (
        <div
          className="cursor-pointer flex items-center my-3 w-full justify-center"
          onClick={removeCouponCode}
        >
          <img className="w-8 h-8" src="/icons/delete-icon.svg" alt="" />
          <p className="text-[#f0663e] font-semibold ml-2 text-sm sm:text-base  md:text-lg">
            Verwijderen
          </p>
        </div>
      ) : (
        <></>
      )}
      <Snackbar
        open={snackBarConfig.open}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        autoHideDuration={2000}
        onClose={handleClose}
      >
        <Alert severity={snackBarConfig.severity} sx={{ width: "80vw" }}>
          {snackBarConfig.message}
        </Alert>
      </Snackbar>
    </div>
  );
};
