import { colors } from "@mui/material";
import React from "react";
import { CustomIconProps } from "./add-01-solid-standard";

const EuroCircleIcon: React.FC<CustomIconProps> = ({
  size = 24,
  color = "#000000",
  ...props
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width={size}
    height={size}
    color={color}
    fill={"none"}
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.75 1C5.81294 1 1 5.81294 1 11.75C1 17.6871 5.81294 22.5 11.75 22.5C17.6871 22.5 22.5 17.6871 22.5 11.75C22.5 5.81294 17.6871 1 11.75 1ZM12.8556 7C11.0023 7 9.5 8.50233 9.5 10.3556V11H8.25C7.83579 11 7.5 11.3358 7.5 11.75C7.5 12.1642 7.83579 12.5 8.25 12.5H9.5V13.1444C9.5 14.9977 11.0023 16.5 12.8556 16.5C13.5177 16.5 14.1337 16.3466 14.6707 16.011C15.207 15.6759 15.62 15.187 15.9208 14.5854C16.1061 14.2149 15.9559 13.7644 15.5854 13.5792C15.2149 13.3939 14.7644 13.5441 14.5792 13.9146C14.38 14.313 14.1398 14.5741 13.8759 14.739C13.6126 14.9034 13.2814 15 12.8556 15C11.8308 15 11 14.1692 11 13.1444V12.5H13.25C13.6642 12.5 14 12.1642 14 11.75C14 11.3358 13.6642 11 13.25 11H11V10.3556C11 9.33076 11.8308 8.5 12.8556 8.5C13.2814 8.5 13.6126 8.59656 13.8759 8.76105C14.1398 8.92595 14.38 9.187 14.5792 9.58541C14.7644 9.95589 15.2149 10.1061 15.5854 9.92082C15.9559 9.73558 16.1061 9.28507 15.9208 8.91459C15.62 8.313 15.207 7.82405 14.6707 7.48895C14.1337 7.15344 13.5177 7 12.8556 7Z"
      fill="currentColor"
    />
  </svg>
);

export default EuroCircleIcon;
