interface SubscriptionPlan {
  duration: number;
  benefits: { [key: string]: boolean };
  state: string;
  createdTime: number;
  cost: string;
  name: string;
  activeFromDate: number;
  planID: string;
  details: string;
  id: string;
}

export const getAmountInEuro = (cost: string): string => {
  let amtInt = parseFloat(cost);
  let amtWithDot = 0;

  if (amtInt > 0) {
    amtWithDot = amtInt / 100;
  }

  return "€ " + amtWithDot.toFixed(2);
};

export type { SubscriptionPlan };
