import { useEffect, useState } from "react";
import { getAmountInEuro, SubscriptionPlan } from "../classes/SubscriptionPlan";
import { CouponCodeCard } from "./ui/CouponCodeCard";
import SubscriptionPlanCard from "./ui/SubscriptionPlanCard";
import { ROOT_NODE } from "../services/firebase";
import {
  FormControlLabel,
  Checkbox,
  CircularProgress,
  iconButtonClasses,
} from "@mui/material";
import { makeNewPaymentTransaction } from "../services/payment-service";
import { CouponCode } from "../classes/CouponCode";
import { PaymentResults } from "./PaymentResults";

interface PaymentOverviewProps {
  selectedPlan: SubscriptionPlan;
  selectedHorseId: string;
  currentUserId: string;
  backButtonClick: () => void;
}

export const PaymentOverview: React.FC<PaymentOverviewProps> = ({
  selectedHorseId,
  selectedPlan,
  currentUserId,
  backButtonClick,
}) => {
  const [finalPayableAmount, setFinalPayAbleAmount] = useState<number>(0);
  const [userHasAcceptedPaymentTerms, setUserHasAcceptedPaymentTerms] =
    useState<boolean>(false);
  const [paymentTermsErrorMessage, setPaymentTermsErrorMessage] =
    useState<string>("");

  const [transactionResponse, setTransactionResponse] = useState<any>();

  const [selectedCouponCode, setSelectedCouponCode] = useState<CouponCode>();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [paymentUrl, setPaymentUrl] = useState<string>("");
  const [transactionId, setTransactionId] = useState<string>("");

  const handleBackClick = () => {
    if (!isLoading) {
      backButtonClick();
    }

    // handle backbutton press
  };

  const openTermsAndConditions = () => {
    const termsUrl: string =
      "https://firebasestorage.googleapis.com/v0/b/allhorsesfirebase-dev-100.appspot.com/o/TermsAndConditions%2Fmet_opmerkingen_Allhorses_Algemene_Voorwaarden.pdf?alt=media&token=a9767f2a-1972-40d8-a0d4-d2cb331c439f";
    window.open(termsUrl, "_blank");
  };

  const openUrlInAnotherTab = (urlString: string) => {
    // console.log("opening url: ", urlString);
    window.open(urlString, "_blank");
  };

  const generateCompletePaymentCallURL = (testMode: boolean) => {
    if (testMode) {
      return `https://us-central1-allhorsesfirebase-dev-100.cloudfunctions.net/generatePaymentUrl?testMode=${testMode}&`;
    } else {
      return `https://us-central1-allhorsesfirebase-dev-100.cloudfunctions.net/generatePaymentUrl?`;
    }
  };

  const makeTransactionRequestAndGetPaymentUrl = async (
    amountInCents: number,
    testMode: boolean
  ) => {
    setIsLoading(true);

    // Step 1: Call the cloud function to generate a payment link for the amount

    const requestOptions: RequestInit = {
      method: "GET",
      redirect: "follow" as RequestRedirect,
    };
    const returnURL: string = process.env.REACT_APP_RETURN_URL ?? "";
    // console.log("returnURL: ", returnURL);

    fetch(
      `${generateCompletePaymentCallURL(
        testMode
      )}returnURL=${returnURL}&amountValue=${amountInCents}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((transactionData) => {
        // console.log("Got transactionData: ", transactionData);
        const gatewayTransactionId: string = transactionData["id"] ?? "";
        const paymentURL: string = transactionData["paymentUrl"] ?? "";
        setPaymentUrl(paymentURL);

        // /*
        // Step 2: Write payment request data to db & Step 3: Write payment request path to db
        makeNewPaymentTransaction(
          transactionData,
          selectedPlan.id,
          selectedPlan.name,
          finalPayableAmount,
          selectedPlan.duration,
          0,
          0,
          gatewayTransactionId,
          selectedHorseId,
          selectedPlan.duration.toString(),
          currentUserId,
          selectedCouponCode
        )
          .then((res) => {
            // console.log("🚀 Order Data Written. and ID: ", res);
            if (res.length > 5) {
              const transactionId: string = res;
              setTransactionId(transactionId);
            }

            setIsLoading(false);
            // If step 2 and step 3 are successful, open paymentURL in another tab

            openUrlInAnotherTab(paymentURL);
          })
          .catch((err) => {
            setIsLoading(false);
          });
        // */
      })
      .catch((error) => {
        console.error(error);
        setIsLoading(false);
      });
  };

  const handlePayButton = () => {
    if (userHasAcceptedPaymentTerms) {
      const testMode: boolean =
        ROOT_NODE === "VtvHorseCollection" ? true : false;
      // console.log("ROOT_NODE: ", ROOT_NODE);
      // console.log("Is Payment in Test Mode: ", testMode);

      makeTransactionRequestAndGetPaymentUrl(finalPayableAmount, testMode);
    } else {
      setPaymentTermsErrorMessage(
        "Accepteer de algemene voorwaarden, Voordat u verdergaat met de betaling, dient u ervoor te zorgen dat u de algemene voorwaarden heeft gelezen en geaccepteerd"
      );
    }
  };

  useEffect(() => {
    setFinalPayAbleAmount(parseInt(selectedPlan.cost));
    setSelectedCouponCode(undefined);
  }, [selectedPlan.cost, selectedCouponCode]);

  return (
    <div>
      <div className="flex justify-center items-start py-4  md:p-6">
        <h2 className="text-4xl w-full text-center font-bold">Overzicht</h2>
      </div>
      {isLoading ? (
        <div className="fixed inset-0 flex items-center justify-center bg-opacity-50 bg-gray-800">
          <CircularProgress />
        </div>
      ) : (
        <></>
      )}

      {transactionId.length <= 0 ? (
        <>
          <div className="flex flex-col justify-start my-4">
            <div className="flex items-center">
              <button
                className="flex justify-around items-center bg-white rounded-full cursor-pointer transition-all duration-300 ease-in-out hover:bg-[#ef9f8b] hover:shadow-lg"
                type="submit"
                onClick={handleBackClick}
              >
                <img
                  className="w-7 h-7"
                  src="/icons/back-full-icon.svg"
                  alt=""
                />
              </button>
              <h3 className="text-xl font-semibold ml-2">Geselecteerd plan</h3>
            </div>
            <SubscriptionPlanCard
              plan={selectedPlan}
              setSelectedPlan={() => {}}
              isSelected={true}
            />
          </div>

          <CouponCodeCard
            selectedPlan={selectedPlan}
            selectedHorseId={selectedHorseId}
            currentUserId={currentUserId}
            setFinalPayAbleAmount={setFinalPayAbleAmount}
            setSelectedCouponCode={setSelectedCouponCode}
          />

          <div className="flex items-center justify-start px-6 mt-16">
            <FormControlLabel
              control={
                <Checkbox
                  checked={userHasAcceptedPaymentTerms}
                  onChange={(e) => {
                    const isChecked = e.target.checked;
                    setUserHasAcceptedPaymentTerms(isChecked);
                    if (isChecked) {
                      setPaymentTermsErrorMessage("");
                    }
                  }}
                  sx={{
                    color: "#F0663E", // Unchecked color
                    "&.Mui-checked": {
                      color: "#F0663E", // Checked color
                    },
                  }}
                />
              }
              label={""}
              style={{ marginRight: 0 }}
            />
            <label className="font-medium text-xl ">
              Ik accepteer de{" "}
              <span
                className="text-[#F0663E] cursor-pointer transition-all duration-300 ease-in-out hover:text-[#c95a41]"
                onClick={openTermsAndConditions}
              >
                voorwaarden
              </span>
            </label>
          </div>
          {paymentTermsErrorMessage ? (
            <p className="text-[#F0663E] font-medium my-2 text-center">
              {paymentTermsErrorMessage}
            </p>
          ) : (
            <></>
          )}
          <button
            className="w-full flex justify-around items-center px-3 py-3 md:px-6 md:py-4 bg-[#F0663E] rounded-3xl cursor-pointer transition-all duration-300 ease-in-out hover:bg-[#cf6044] hover:shadow-lg mt-2"
            type="submit"
            onClick={handlePayButton}
          >
            <p className="loginButtonText text-lg sm:text-xl  md:text-2xl">
              Klik om te betalen |{" "}
              {getAmountInEuro(finalPayableAmount.toString())}
            </p>
          </button>
        </>
      ) : transactionId ? (
        <PaymentResults userId={currentUserId} transactionId={transactionId} />
      ) : (
        <></>
      )}
    </div>
  );
};
