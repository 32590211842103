interface HorseAd {
  age: number;
  breedId: number;
  category: string;
  color: string;
  colorId: number;
  contactViaWhatsApp: boolean;
  createdtime: number;
  description: string;
  documents: string[];
  examination: string;
  expiryTime?: number;
  favourite: boolean;
  gender: string;
  height: number;
  id: string;
  image: string;
  isFreshAd?: boolean;
  lastPaymentTime: number;
  level: string;
  location: AdLocation;
  media: string[];
  name: string;
  planName: string;
  price: Price;
  selleruserId: string;
  sexId: number;
  status: number;
  studbook: string;
}

interface AdLocation {
  addressLine1: string;
  addressLine2: string;
  area_code: string;
  city: string;
  country: string;
  state: string;
  lat: number;
  longitude: number;
}

interface Price {
  actualAmount: number;
  currency: string;
  fromTo: number;
  to: number;
}

export function getHorseAge(ad: HorseAd): string {
  const dob = new Date(ad.age); // Convert ad's age to a Date object
  const createdDate = new Date(ad.createdtime); // Convert createdtime to a Date object

  // console.log("Dob =", dob);
  // console.log("Created Date =", createdDate);

  const calculatedAge = calculateAge(dob, createdDate); // Calculate age using dob and createdDate

  // console.log("Calculated Age =", calculatedAge);

  return calculatedAge.toString();
}

function calculateAge(dob: Date, createdDate: Date): number {
  const diffMs = createdDate.getTime() - dob.getTime(); // Difference in milliseconds
  const ageDate = new Date(diffMs); // Convert the difference into a Date object
  return Math.abs(ageDate.getUTCFullYear() - 1970); // Calculate the age
}

export const HORSE_COLORS = [
  "Vos",
  "Bruin",
  "Donkerbruin",
  "Zwart",
  "Schimmel",
  "Palomino",
  "Bont",
  "Roan bruin",
  "Anders",
];
export const HORSE_STUDBOOKS = [
  "KWPN",
  "NRPS",
  "BWP",
  "Hannover",
  "Holstein",
  "SBS",
  "Westfalen",
  "Zangerheide",
  "Oldenberg",
  "Fries",
  "Pre",
  "Quarter horse",
  "Arabier",
  "Bont paard",
  "Deens warmbloed",
  "Groninger stamboek",
  "Trakehnen",
  "Volbloed",
  "Warmbloed",
  "Zweedse warmbloed",
  "Anders",
];
export const HORSE_GENDERS = ["Merrie", "Ruin", "Hengst"];
export const HORSE_EXAMINATIONS = [
  "Klinisch",
  "Röntgenlogisch",
  "Röntgenlogisch en Klinisch",
  "Niet van toepassing",
];
export const HORSE_LEVELS = [
  "Onbeleerd",
  "Zadelmak",
  "B",
  "L",
  "M",
  "Z",
  "ZZ",
  "Lichte tour",
  "Zware tour",
  "Internationaal",
  "1.00M",
  "1.10M",
  "1.20M",
  "1.30M",
  "1.40M",
];
export const HORSE_CATEGORIES = [
  "Dressuurpaarden",
  "Springpaarden",
  "Recreatief",
  "Allround",
  "Fokmerries",
];

export const ageInMilliseconds = (ageInNumber: string): number => {
  const currentTimeInMs = Date.now(); // Equivalent to System.currentTimeMillis() in JavaScript
  const ageInYears = parseInt(ageInNumber);
  const ageInMs = ageInYears * 365 * 24 * 60 * 60 * 1000; // Convert age in years to milliseconds

  return currentTimeInMs - ageInMs;
};

export const isAdExpired = (expiryTime: number): boolean => {
  const currentTimestamp = new Date().getTime(); // Current time in milliseconds

  if (currentTimestamp > expiryTime) {
    return true;
  }
  return false;
};

export const getExpiryLabelAndColor = (
  expiryTime: number
): {
  label: string;
  color: string;
} => {
  let isExpired = false;
  const expDate = expiryTime;
  const oneDayInMs = 24 * 60 * 60 * 1000; // 1 day in milliseconds

  const currentTimestamp = new Date().getTime(); // Current time in milliseconds

  if (currentTimestamp > expDate) {
    // console.log("GOOD dt", expDate);
    isExpired = true;
  }

  if (expDate === 0) {
    return { label: "Freemium", color: "#009A19" }; // Replace with localization function if needed
  } else {
    if (!isExpired) {
      const daysInMs = expDate - currentTimestamp;
      const days = Math.floor(daysInMs / oneDayInMs);

      if (days === 0) {
        return { label: "Verloopt vandaag!", color: "#FB9900" }; // Replace with localization function
      } else if (days === 1) {
        return { label: `Verloopt in ${days} dag!`, color: "#FB9900" }; // Replace with localization function
      } else {
        return { label: `Verloopt in ${days} dagen!`, color: "#FB9900" }; // Replace with localization function
      }
    } else {
      return { label: "Verlopen!", color: "#FF1A1A" }; // Replace with localization function
    }
  }
};

export type { HorseAd, AdLocation, Price };
