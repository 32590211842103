import { CustomIconProps } from "./add-01-solid-standard";

const RepeatIcon: React.FC<CustomIconProps> = ({
  size = 24,
  color = "#000000",
  ...props
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width={size}
    height={size}
    color={color}
    fill={"none"}
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.1732 7.17984C20.6262 6.86388 21.2495 6.97495 21.5655 7.42793C22.4696 8.72414 23 10.3016 23 12C23 16.4183 19.4183 20 15 20H8.26204L9.14017 20.7318C9.56445 21.0854 9.62178 21.7159 9.26821 22.1402C8.91465 22.5645 8.28408 22.6218 7.85981 22.2682L4.85981 19.7682C4.53637 19.4987 4.41639 19.0555 4.55972 18.6596C4.70304 18.2637 5.07897 18 5.49999 18H15C18.3137 18 21 15.3137 21 12C21 10.7239 20.6027 9.54358 19.9251 8.57212C19.6092 8.11915 19.7202 7.4958 20.1732 7.17984Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.8268 16.8202C3.37382 17.1362 2.75047 17.0251 2.43451 16.5721C1.53038 15.2759 1.00001 13.6984 1.00001 12C1.00001 7.58173 4.58173 4 9.00001 4H15.738L14.8598 3.26822C14.4355 2.91466 14.3782 2.2841 14.7318 1.85982C15.0854 1.43554 15.7159 1.37822 16.1402 1.73178L19.1402 4.23178C19.4636 4.50131 19.5836 4.94456 19.4403 5.34043C19.297 5.7363 18.921 6 18.5 6H9.00001C5.6863 6 3.00001 8.6863 3.00001 12C3.00001 13.2761 3.39727 14.4564 4.07488 15.4279C4.39084 15.8809 4.27977 16.5042 3.8268 16.8202Z"
      fill="currentColor"
    />
  </svg>
);

export default RepeatIcon;
