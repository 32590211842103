import React, { useEffect, useState } from "react";

export const getDominantColor = (image: HTMLImageElement): string => {
  const canvas = document.createElement("canvas");
  const context = canvas.getContext("2d");

  if (!context) return "rgba(255, 255, 255, 0.5)"; // Fallback color

  canvas.width = image.width;
  canvas.height = image.height;
  context.drawImage(image, 0, 0, image.width, image.height);
  const imageData = context.getImageData(0, 0, canvas.width, canvas.height);
  const data = imageData.data;

  const colorCounts: { [key: string]: number } = {};
  let dominantColor = "";
  let maxCount = 0;

  for (let i = 0; i < data.length; i += 4) {
    // Get the RGB values
    const r = data[i];
    const g = data[i + 1];
    const b = data[i + 2];

    // Create a color string
    const colorString = `rgb(${r},${g},${b})`;

    // Count the occurrences of each color
    colorCounts[colorString] = (colorCounts[colorString] || 0) + 1;

    // Determine the dominant color
    if (colorCounts[colorString] > maxCount) {
      maxCount = colorCounts[colorString];
      dominantColor = colorString;
    }
  }

  return dominantColor;
};

const extractDominantColor = (imgElement: HTMLImageElement): string => {
  const canvas = document.createElement("canvas");
  canvas.width = imgElement.width;
  canvas.height = imgElement.height;
  const ctx = canvas.getContext("2d");

  if (!ctx) return "rgba(255, 255, 255, 0.5)"; // Fallback color

  ctx.drawImage(imgElement, 0, 0, canvas.width, canvas.height);

  const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height).data;
  let r = 0,
    g = 0,
    b = 0;

  // Sample every 10th pixel for performance
  for (let i = 0; i < imageData.length; i += 40) {
    r += imageData[i];
    g += imageData[i + 1];
    b += imageData[i + 2];
  }

  const pixelCount = imageData.length / 4 / 10;
  r = Math.round(r / pixelCount);
  g = Math.round(g / pixelCount);
  b = Math.round(b / pixelCount);

  return `rgba(${r}, ${g}, ${b}, 0.5)`;
};

const useImageDominantColor = (imageUrl: string): string => {
  const [dominantColor, setDominantColor] = useState<string>(
    "rgba(255, 255, 255, 0.5)"
  );

  useEffect(() => {
    const img = new Image();
    img.crossOrigin = "anonymous";
    img.onload = () => {
      const color = extractDominantColor(img);
      setDominantColor(color);
    };
    img.src = imageUrl;
  }, [imageUrl]);

  return dominantColor;
};

export { useImageDominantColor };
