import { UsedCouponCode } from "./UsedCouponCodes";

export interface CouponCode {
  id: string;
  created_time: number;
  code: string;
  valid_from: number;
  valid_until: number;
  single_use_per_user: boolean;
  discount_type: string;
  discount_percentage: number;
  flat_discount_euros: number;
  applicable_to: string[];
  state: number;
}

// Functions that perform the same logic as in Kotlin
export const isValidState = (coupon: CouponCode): boolean => {
  return coupon.state === 1;
};

export const isValid = (
  coupon: CouponCode,
  usedCouponCodesByUser: UsedCouponCode[]
): boolean => {
  if (!isValidState(coupon)) {
    return false;
  }

  // Check if it's a single-use coupon and has been used by the user
  if (coupon.single_use_per_user) {
    return !usedCouponCodesByUser.some(
      (usedCoupon) => usedCoupon.coupon_code_id === coupon.id
    );
  }

  // Otherwise, it's valid for multiple uses
  return true;
};

export const isFlatDiscount = (coupon: CouponCode): boolean => {
  return coupon.discount_type === "FLAT";
};

export const getDiscountedAmount = (
  coupon: CouponCode,
  originalAmountInCents: number
): number => {
  if (isFlatDiscount(coupon)) {
    return originalAmountInCents - coupon.flat_discount_euros * 100;
  } else {
    const discountPercentage = coupon.discount_percentage / 100.0;
    const discountInEuros = originalAmountInCents * discountPercentage;
    return originalAmountInCents - discountInEuros;
  }
};

export const getDiscountAmount = (
  coupon: CouponCode,
  originalAmountInCents: number
): number => {
  if (isFlatDiscount(coupon)) {
    return coupon.flat_discount_euros * 100;
  } else {
    const discountPercentage = coupon.discount_percentage / 100.0;
    const discountInEuros = originalAmountInCents * discountPercentage;
    return discountInEuros;
  }
};

export const isStringApplicable = (coupon: CouponCode, id: string): boolean => {
  return coupon.applicable_to.includes(id);
};

// export const isCouponCodeValidForServices = (
//   coupon: CouponCode,
//   idOfServices: string
// ): boolean => {
//   return coupon.applicable_to.some((applicableId) =>
//     idOfServices.includes(applicableId)
//   );
// };
