import { useContext, useEffect, useState } from "react";
import Header from "./ui/Header";
import { useNavigate } from "react-router-dom";
import { GlobalContext } from "../contexts/GlobalContext";

import HorseAdCard from "./ui/HorseAdCard";
import { HorseAd } from "../classes/HorseAd";
import HorseDetails from "./HorseDetails";
import { getMyHorsesFromFirestoreWhereEquals } from "../services/firebase";
import { PlaceNewAd } from "./PlaceNewAd";

const MyHorses = () => {
  const { currentUser, getCurrentUser } = useContext(GlobalContext);
  const [selectedAd, setSelectedAd] = useState<HorseAd | null>(null);
  const [ads, setAds] = useState<HorseAd[]>([]);
  const [adSetForEditing, setAdSetForEditing] = useState(false);
  const [isAdForRenewal, setIsAdForRenewal] = useState(false);
  // const [filteredAds, setFilteredAds] = useState<HorseAd[]>(ads);
  const navigate = useNavigate();

  const fetchAndProcessAds = async () => {
    try {
      const res = await getMyHorsesFromFirestoreWhereEquals(currentUser.id);
      const docs = res.docs;

      console.log("Fetched docs:", docs.length);

      // Separate the documents with expiryTime from those without
      const docsWithExpiry = docs.filter((doc) => doc.data().expiryTime);
      const docsWithoutExpiry = docs.filter((doc) => !doc.data().expiryTime);

      // Sort the docs with expiryTime in descending order based on expiryTime
      docsWithExpiry.sort((a, b) => b.data().expiryTime - a.data().expiryTime);

      // Sort the docs without expiryTime in descending order based on createdtime
      docsWithoutExpiry.sort(
        (a, b) => b.data().createdtime - a.data().createdtime
      );

      // Combine the sorted arrays
      const sortedDocs = [...docsWithExpiry, ...docsWithoutExpiry];

      // Remove duplicates
      const uniqueDocs = Array.from(
        new Set(sortedDocs.map((doc) => doc.id))
      ).map((id) => sortedDocs.find((doc) => doc.id === id));

      // const fetchedAds = uniqueDocs.map((doc) => ({
      //   id: doc.id,
      //   ...(doc.data() as Omit<HorseAd, "id">),
      // }));

      const fetchedAds = uniqueDocs
        .filter((doc) => doc !== undefined) // Ensure doc is not undefined
        .map((doc) => {
          if (doc) {
            // Additional check for safety
            return {
              id: doc.id,
              ...(doc.data() as Omit<HorseAd, "id">),
            };
          }
          return null; // Return null if doc is undefined, but this should be handled
        })
        .filter((ad) => ad !== null) as HorseAd[]; // Remove null values from the array

      const activeAds = fetchedAds;
      // const activeAds = fetchedAds.filter((ad: HorseAd) => isValid(ad));

      setAds(activeAds);
      // setFilteredAds(activeAds);
    } catch (error) {
      console.error("Error fetching ads:", error);
    }
  };

  useEffect(() => {
    fetchAndProcessAds();
  }, []);

  function isValid(ad: HorseAd): boolean {
    const status = ad?.status;

    if (status != 2 && status <= 4) {
      return true;
    }

    return false;
  }

  const adOnClick = (ad: HorseAd) => {
    setSelectedAd(ad);
  };

  const setAdForEdit = (ad: HorseAd) => {
    setSelectedAd(ad);
    setAdSetForEditing(true);
  };

  const onClickRenewPlan = () => {
    setIsAdForRenewal(true);
  };

  const closeDetailsView = () => {
    console.log("close button called");
    setSelectedAd(null);
  };

  const closeEditView = () => {
    console.log("Edit close button called");
    setSelectedAd(null);
    setAdSetForEditing(false);
    setIsAdForRenewal(false);
  };

  return (
    <div className="mainFrame">
      <Header onClickClose={closeEditView} />
      <div className="flex justify-center items-center p-1 bg-[#ffffff00] relative w-full">
        {/* For transparent edges */}
        {/* {showFilter && (
          <div className="fixed inset-0 flex justify-center items-center bg-gray-800 bg-opacity-50 z-50">
            <div className="bg-white p-1 rounded-3xl shadow-xl">
              <Filter
                ads={ads}
                setFilteredAds={setFilteredAds}
                hideFilter={() => setShowFilter(false)}
              />
            </div>
          </div>
        )} */}

        <div className="flex flex-col py-2 md:px-7 md:bg-[#386163] rounded-3xl relative ">
          {/* The Main Container */}
          <div className="flex justify-center items-start p-4  mt-4 mb-4">
            <h2 className="text-4xl w-full text-center font-bold">
              {!adSetForEditing ? "Mijn paarden" : "Advertentie bewerken"}
            </h2>
          </div>

          {/* {selectedAd == null ? (
              <SecondaryCTAButtons
                label={"Filter"}
                onClick={() => setShowFilter(true)}
                ButtonIcon={FilterMailCircleIcon}
                className="h-14 w-14"
                displayIcon={true}
              ></SecondaryCTAButtons>
            ) : (
              <></>
            )} */}
          {/* </div> */}

          <div className="flex flex-row justify-center pt-0 rounded-3xl  overflow-y-auto w-[90vw]">
            {/* Horse Card Container */}
            {ads.length === 0 ? (
              <p className="text-center text-gray-500">
                Even geduld, advertenties worden geladen ...
              </p>
            ) : // : filteredAds.length === 0 ? (
            //   <p className="text-center text-gray-500">
            //     Geen advertenties gevonden voor het geselecteerde filter!
            //   </p>
            // )

            // hide in condition 1 & 2
            selectedAd == null || !(isAdForRenewal || adSetForEditing) ? (
              <div className="h-full flex flex-wrap justify-evenly  w-[inherit]">
                {ads.map((ad: HorseAd) => (
                  <HorseAdCard
                    key={ad.name + ad.id}
                    ad={ad}
                    onClick={() => adOnClick(ad)}
                    onClickEdit={() => setAdForEdit(ad)}
                    onClickRenewPlan={() => onClickRenewPlan()}
                    isMyHorsesPage={true}
                  />
                ))}
              </div>
            ) : (
              <></>
            )}

            {/*condition:1 Show when the is isAdForRenewal is true*/}
            {selectedAd !== null && isAdForRenewal && (
              <PlaceNewAd
                ad={selectedAd}
                isEdit={true}
                isPlanRenewal={true}
                onClickClose={() => closeEditView()}
              />
            )}

            {/*condition:2 Show when the is adSetForEditing is true*/}
            {selectedAd !== null && adSetForEditing && (
              <PlaceNewAd
                ad={selectedAd}
                isEdit={adSetForEditing}
                onClickClose={() => closeEditView()}
              />
            )}
            {/* 
            {selectedAd !== null && !adSetForEditing && (
              <HorseDetails
                key={selectedAd.id}
                ad={selectedAd}
                onClickClose={closeDetailsView}
              />
            )} */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyHorses;
