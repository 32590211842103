"use client";

import React, { useEffect, useRef, useState } from "react";

import HorseAdCard from "./HorseAdCard";
import { HorseAd } from "../../classes/HorseAd";

interface RelatedAdsProps {
  thisHorse: HorseAd;
  ads: HorseAd[];
  adRelatedAdOnClick: (ad: HorseAd) => void;
}

const getPrice = (rawPrice: number): string => {
  if (rawPrice == 0) {
    return "Prijs op aanvraag";
  }
  // Convert the number to a string
  const priceString = rawPrice.toString();

  // Insert dots to separate thousands
  const parts = [];
  let i = priceString.length;

  // Process the string in reverse, adding dots every three digits
  while (i > 0) {
    parts.push(priceString.slice(Math.max(0, i - 3), i));
    i -= 3;
  }

  // Join parts and reverse the order
  return parts.reverse().join(".");
};

const getShortName = (ogName: string): string => {
  if (ogName.length == 40) {
    return ogName;
  }

  return ogName.slice(0, 37) + " ...";
};

const RelatedAds: React.FC<RelatedAdsProps> = ({
  thisHorse,
  ads,
  adRelatedAdOnClick,
}) => {
  const [relatedAds, setRelatedAds] = useState<HorseAd[]>(ads);
  const scrollContainerRef = useRef<HTMLDivElement | null>(null);
  const scrollSpeed = 1; // Adjust as needed
  const intervalRef = useRef<number | null>(null);

  const openDetailsPage = (ad: HorseAd) => {
    adRelatedAdOnClick(ad); // Pass the ad to the click handler
  };

  const getRelatedAds = (length: number = 5): HorseAd[] => {
    console.log("✅ Called getRelatedAds(), with validHorses: ", ads.length);

    let relatedAds = ads.filter((otherHorse) => {
      const idCondition = otherHorse.id !== thisHorse.id;
      const breedCondition = otherHorse.breedId === thisHorse.breedId;
      const colorCondition = otherHorse.colorId === thisHorse.colorId;
      const genderCondition = otherHorse.gender === thisHorse.gender;
      const heightCondition = otherHorse.height === thisHorse.height;

      return (
        idCondition &&
        breedCondition &&
        colorCondition &&
        genderCondition &&
        heightCondition
      );
    });

    const additionalAdsNeeded = Math.max(0, length - relatedAds.length);

    // Create a shallow copy of the ads array before shuffling to avoid mutating the original array
    const shuffledHorses = [...ads].sort(() => Math.random() - 0.5);

    // Select additional horses, avoiding duplicates
    const additionalAds = shuffledHorses
      .filter((otherHorse) => {
        return !relatedAds.some((relatedAd) => relatedAd.id === otherHorse.id);
      })
      .slice(0, additionalAdsNeeded);

    // Add the additional horses to the final array
    relatedAds = [...relatedAds, ...additionalAds];

    return relatedAds;
  };

  useEffect(() => {
    setRelatedAds(getRelatedAds());
  }, [ads, thisHorse]); // Include ads and thisHorse in the dependency array

  return (
    <div className="mt-8">
      <h2 className="text-xl font-semibold text-[#FFF]">
        Misschien vind je het ook leuk
      </h2>

      <div
        // ref={scrollContainerRef}
        className="mt-4 w-[87vw] flex justify-start pt-0 bg-[#E5E9E300] rounded-3xl overflow-x-auto pb-10 scroll-visible"
      >
        {/* Ensure horizontal scrolling with no wrapping */}
        <div className="h-full flex flex-row justify-start space-x-4">
          {relatedAds.map((ad: HorseAd) => (
            <HorseAdCard
              key={ad.name + ad.id}
              ad={ad}
              onClick={() => openDetailsPage(ad)}
              isMyHorsesPage={false}
              isRelatedAds={true}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default RelatedAds;
