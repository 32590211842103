import React from "react";
import { CustomIconProps } from "./add-01-solid-standard";

const MailSend02Icon: React.FC<CustomIconProps> = ({
  size = 24,
  color = "#000000",
  ...props
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width={size}
    height={size}
    color={color}
    fill={"none"}
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.75 3C2.23122 3 1 4.23122 1 5.75V17.75C1 19.2688 2.23122 20.5 3.75 20.5H16.8646C16.7882 20.2563 16.75 20.0031 16.75 19.75H14.25C12.8693 19.75 11.75 18.6307 11.75 17.25C11.75 15.8693 12.8693 14.75 14.25 14.75H16.75C16.75 14.1102 16.9941 13.4704 17.4822 12.9822C18.4585 12.0059 20.0415 12.0059 21.0178 12.9822L22.5 14.4645V5.75C22.5 4.23122 21.2688 3 19.75 3H3.75ZM6.12803 7.10229C5.77024 6.89358 5.311 7.01443 5.10229 7.37222C4.89358 7.73001 5.01443 8.18925 5.37222 8.39796L10.3645 11.3101C11.2207 11.8096 12.2795 11.8096 13.1358 11.3101L18.128 8.39796C18.4858 8.18925 18.6067 7.73001 18.398 7.37222C18.1892 7.01443 17.73 6.89358 17.3722 7.10229L12.38 10.0144C11.9908 10.2415 11.5095 10.2415 11.1203 10.0144L6.12803 7.10229ZM14.5 16.25C13.9477 16.25 13.5 16.6977 13.5 17.25C13.5 17.8023 13.9477 18.25 14.5 18.25H18.7929V20.0429C18.7929 20.3081 18.89 20.5861 19.1372 20.6821C19.4955 20.8214 19.9179 20.7464 20.2071 20.4571L22.7071 17.9571C23.0976 17.5666 23.0976 16.9334 22.7071 16.5429L20.2071 14.0429C19.9179 13.7536 19.4955 13.6786 19.1372 13.8179C18.89 13.9139 18.7929 14.1919 18.7929 14.4571V16.25H14.5Z"
      fill="currentColor"
    />
  </svg>
);

export default MailSend02Icon;
