import React, { RefObject } from "react";
import { InputFieldErrorMessage } from "./CustomTextInput";

interface CustomDropDownInputProps {
  isValid: boolean;
  label: string;
  dropdownValues: string[];
  selectedValue: string;
  className?: string;
  errorMessage?: string;
  setValue: (e: any) => void;
}

export const CustomDropDownInput: React.FC<CustomDropDownInputProps> = ({
  isValid,
  label,
  dropdownValues,
  selectedValue,
  className = "",
  setValue,
  errorMessage = "",
}) => {
  return (
    <div className={`form-row my-6 ${className}`}>
      <p className="form-element flex flex-col">
        <label className="font-semibold text-xl mb-2 ml-3">{label}</label>

        <select
          value={selectedValue}
          onChange={(e) => setValue(e.target.value)}
          className={
            isValid
              ? "form-input-element"
              : "form-input-element form-field-error"
          }
          //   className="block w-full p-2 border border-gray-300 rounded-md"
        >
          {dropdownValues.map((value: string, index) => (
            <option key={value + index} value={value}>
              {value}
            </option>
          ))}
        </select>
        <InputFieldErrorMessage message={errorMessage} isValid={isValid} />
      </p>
    </div>
  );
};
