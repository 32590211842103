import React from "react";
import { CustomIconProps } from "./add-01-solid-standard";

const WhatsappIcon: React.FC<CustomIconProps> = ({
  size = 24,
  color = "#000000",
  ...props
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width={size}
    height={size}
    color={color}
    fill={"none"}
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.0019 1.24805C6.06381 1.24805 1.25 6.06186 1.25 12C1.25 13.9012 1.66487 15.561 2.5385 17.1125L1.27747 21.8054C1.2079 22.0643 1.28182 22.3406 1.47136 22.5302C1.6609 22.7198 1.93724 22.7939 2.19617 22.7244L6.86371 21.4717C8.4265 22.3556 10.0955 22.752 12.0019 22.752C17.9401 22.752 22.7539 17.9381 22.7539 12C22.7539 6.06186 17.9401 1.24805 12.0019 1.24805ZM7 6.25C6.80107 6.25 6.61029 6.32903 6.46964 6.4697C6.32898 6.61037 6.24998 6.80116 6.25 7.00009C6.25019 8.58623 6.65729 10.2803 7.45722 11.8459L7.46094 11.8532C8.29315 13.4791 9.54922 14.9661 11.2134 16.0494C11.3835 16.1602 11.5583 16.2665 11.7375 16.3682C11.7451 16.3726 11.7527 16.377 11.7604 16.3811C13.2708 17.2328 15.0869 17.75 17 17.75C17.4142 17.75 17.75 17.4142 17.75 17V14.381C17.75 14.0289 17.505 13.7242 17.1611 13.6485L13.8958 12.9304C13.6146 12.8685 13.3228 12.9731 13.1449 13.1995L11.9407 14.7323C10.7738 13.9524 9.8438 12.9368 9.15926 11.8173L10.8245 10.9533C11.1417 10.7887 11.2987 10.4231 11.1997 10.0797L10.2514 6.79214C10.1588 6.47107 9.86491 6.25 9.53075 6.25H7Z"
      fill="currentColor"
    />
  </svg>
);

export default WhatsappIcon;
