interface SpecsInfoProps {
  label: string;
  value: string;
  isURL?: boolean;
}

const SpecsInfo: React.FC<SpecsInfoProps> = ({ label, value, isURL }) => {
  return (
    <div className="flex mb-2 flex-wrap">
      <h3 className="text-base w-28 font-semibold text-[#FFF] mr-2">{label}</h3>
      {isURL ? (
        <p className="text-[#FFF] text-base">
          :{" "}
          {
            <a
              href={value}
              className="text-[#f0663e] text-base"
              target="_blank"
              rel="noopener noreferrer"
            >
              {value}
            </a>
          }
        </p>
      ) : (
        <p className="text-[#FFF] text-base">: {value}</p>
      )}
    </div>
  );
};

export default SpecsInfo;
