import { CustomIconProps } from "./add-01-solid-standard";

const Invoice01Icon: React.FC<CustomIconProps> = ({
  size = 24,
  color = "#000000",
  ...props
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width={size}
    height={size}
    color={color}
    fill={"none"}
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.25 4C3.25 2.48122 4.48122 1.25 6 1.25H18C19.5188 1.25 20.75 2.48122 20.75 4V21.5C20.75 21.7599 20.6154 22.0013 20.3943 22.138C20.1732 22.2746 19.8971 22.2871 19.6646 22.1708L16.0408 20.3589L12.3975 22.636C12.1543 22.788 11.8457 22.788 11.6025 22.636L7.95919 20.3589L4.33541 22.1708C4.10292 22.2871 3.82681 22.2746 3.6057 22.138C3.38459 22.0013 3.25 21.7599 3.25 21.5V4ZM7.5 11C7.5 10.4477 7.94772 10 8.5 10H11.5C12.0523 10 12.5 10.4477 12.5 11C12.5 11.5523 12.0523 12 11.5 12H8.5C7.94772 12 7.5 11.5523 7.5 11ZM8.5 6C7.94772 6 7.5 6.44772 7.5 7C7.5 7.55228 7.94772 8 8.5 8H14.5C15.0523 8 15.5 7.55228 15.5 7C15.5 6.44772 15.0523 6 14.5 6H8.5Z"
      fill="currentColor"
    />
  </svg>
);

export default Invoice01Icon;
