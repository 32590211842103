import { doc, onSnapshot } from "firebase/firestore";
import { firestore } from "../services/firebase";
import { useEffect, useState } from "react";
import { CircularProgress } from "@mui/material";
import SecondaryCTAButtons from "./ui/SecondaryCTAButtons";
import { useNavigate } from "react-router-dom";
import HomeIcon from "../assets/icons/home-icon";

interface PaymentResultsProps {
  userId: string;
  transactionId: string;
}

export const PaymentResults: React.FC<PaymentResultsProps> = ({
  userId,
  transactionId,
}) => {
  const navigate = useNavigate();
  const [orderStatusId, setOrderStatusId] = useState<string | null>(null);

  const listenToPaymentStatus = (userId: string, transactionId: string) => {
    const docPath = `PayNl/${userId}/PaymentResults/${transactionId}`;
    const docRef = doc(firestore, docPath);

    const unsubscribe = onSnapshot(
      docRef,
      (docSnapshot) => {
        if (docSnapshot.exists()) {
          const data = docSnapshot.data();
          console.log("Document data:", data);

          const orderStatusId = data?.orderStatusId;

          setOrderStatusId(orderStatusId);

          if (orderStatusId === "100") {
            console.log("Payment Success");
          } else {
            console.log("Payment Failed");
          }
        } else {
          console.log("No such document!");
        }
      },
      (error) => {
        console.log("Error listening to document:", error);
      }
    );

    return unsubscribe; // Return unsubscribe function to stop listening if needed
  };

  useEffect(() => {
    const unsubscribe = listenToPaymentStatus(userId, transactionId);

    // Cleanup function to unsubscribe when component unmounts
    return () => {
      unsubscribe();
    };
  }, [userId, transactionId]);

  const getResultText = (statusId: any) => {
    // if (typeof statusId == typeof "") {
    // }

    if (statusId === 100 || statusId === "100") {
      return { msg: "🎉 Successful!", color: "#089608" };
    }
    return { msg: "⚠️ Failed!", color: "#F0663E" };
  };

  return (
    <div>
      {/* {true? ( */}
      {orderStatusId === null ? (
        <div className="fixed inset-0 flex items-center justify-center bg-opacity-50 bg-gray-800">
          <CircularProgress />
        </div>
      ) : (
        <div className="flex justify-center items-center flex-col">
          <p
            className="font-bold text-4xl my-4"
            style={{ color: `${getResultText(orderStatusId).color}` }}
          >
            Betaling:
            {getResultText(orderStatusId).msg}
          </p>

          <div
            className={` flex justify-center items-center px-5 py-3 bg-[#10393B] rounded-full cursor-pointer transition-all duration-300 ease-in-out hover:bg-[#386163] hover:shadow-lg mb-4 mt-10`}
            onClick={() => {
              navigate("/");
            }}
          >
            <img className="h-9 mr-2" src="/icons/home_button.svg" alt="" />

            <p className="font-semibold text-lg  text-white transition-colors duration-300 ease-in-out pr-2">
              Ga Home
            </p>
          </div>
        </div>
      )}
    </div>
  );
};
