import {
  getAmountInEuro,
  SubscriptionPlan,
} from "../../classes/SubscriptionPlan";

interface SubscriptionPlanCardProps {
  plan: SubscriptionPlan;
  setSelectedPlan: (plan: SubscriptionPlan) => void;
  isSelected?: boolean;
}

const SubscriptionPlanCard: React.FC<SubscriptionPlanCardProps> = ({
  plan,
  setSelectedPlan,
  isSelected = false,
}) => {
  return (
    <div
      className={`${
        isSelected ? "bg-[#ef9667]" : "bg-[#ffb188]"
      } rounded-[2em] w-full px-6 py-6 my-6 cursor-pointer hover:bg-[#ef9667] transition-all duration-300 ease-in-out`}
      onClick={() => setSelectedPlan(plan)}
    >
      <div className="flex flex-col justify-start">
        <h3 className="text-white font-bold text-xl">{plan.name}</h3>
        <p className="text-white text-lg">{plan.details}</p>
      </div>

      <div className="flex justify-between items-center w-full rounded-full bg-white px-4 py-3 mt-4">
        <p className="font-bold text-lg text-[#ffb188]">
          {getAmountInEuro(plan.cost)}
        </p>
        <img className="w-9 h-9" src="/icons/arrow-right.svg" alt="" />
      </div>
    </div>
  );
};

export default SubscriptionPlanCard;
