import React from "react";
import { CustomIconProps } from "./add-01-solid-standard";

const MapsLocation01Icon: React.FC<CustomIconProps> = ({
  size = 24,
  color = "#000000",
  ...props
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width={size}
    height={size}
    color={color}
    fill={"none"}
    {...props}
  >
    <path
      d="M11.379 19.2645L8.75 18.1378V1.50586L14.25 3.863V10.5821C12.1637 11.7304 10.75 13.9499 10.75 16.4996C10.75 17.538 11.0009 18.4616 11.379 19.2645Z"
      fill="currentColor"
    />
    <path
      d="M15.75 9.9787V4.25041H21C21.9665 4.25041 22.75 5.03391 22.75 6.00041V12.2566C21.5125 10.7274 19.6204 9.74963 17.5 9.74963C16.8948 9.74963 16.3081 9.82929 15.75 9.9787Z"
      fill="currentColor"
    />
    <path
      d="M7.24976 1.537L2.21738 4.05319C1.6245 4.34963 1.25 4.95559 1.25 5.61844V17.613C1.25 18.8075 2.42022 19.6509 3.5534 19.2732L7.25 18.041L7.24976 1.537Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.5 11.25C14.6005 11.25 12.25 13.6005 12.25 16.5C12.25 18.2068 13.1471 19.5656 14.1073 20.5329C15.0707 21.5035 16.17 22.1556 16.7514 22.4646C17.2214 22.7144 17.7786 22.7144 18.2486 22.4646C18.83 22.1556 19.9293 21.5035 20.8927 20.5329C21.8529 19.5656 22.75 18.2068 22.75 16.5C22.75 13.6005 20.3995 11.25 17.5 11.25ZM17.4911 14.498C16.3914 14.498 15.5 15.3935 15.5 16.498C15.5 17.6026 16.3914 18.498 17.4911 18.498H17.5089C18.6086 18.498 19.5 17.6026 19.5 16.498C19.5 15.3935 18.6086 14.498 17.5089 14.498H17.4911Z"
      fill="currentColor"
    />
  </svg>
);

export default MapsLocation01Icon;
