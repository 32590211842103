import React from "react";
import { CustomIconProps } from "./add-01-solid-standard";

const LinkBackwardIcon: React.FC<CustomIconProps> = ({
  size = 24,
  color = "#000000",
  ...props
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width={size}
    height={size}
    color={color}
    fill={"none"}
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.037 4.05711C10.3173 4.17319 10.5 4.44667 10.5 4.75002V8.50002H14.75C18.4779 8.50002 21.5 11.5221 21.5 15.25V18.75C21.5 19.0805 21.2837 19.3721 20.9673 19.4678C20.651 19.5636 20.3093 19.441 20.126 19.166L19.7978 18.6737C18.2677 16.3786 15.6918 15 12.9333 15H10.5V18.75C10.5 19.0534 10.3173 19.3268 10.037 19.4429C9.75676 19.559 9.43417 19.4948 9.21967 19.2803L2.21967 12.2803C1.92678 11.9875 1.92678 11.5126 2.21967 11.2197L9.21967 4.21969C9.43417 4.00519 9.75676 3.94102 10.037 4.05711Z"
      fill="currentColor"
    />
  </svg>
);

export default LinkBackwardIcon;
