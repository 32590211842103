import React from "react";
import { CustomIconProps } from "./add-01-solid-standard";

const PencilEdit02Icon: React.FC<CustomIconProps> = ({
  size = 24,
  color = "#000000",
  ...props
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width={size}
    height={size}
    color={color}
    fill={"none"}
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.91668 5.94444C4.37973 5.94444 3.94445 6.37972 3.94445 6.91667V18.5833C3.94445 19.1203 4.37973 19.5556 4.91668 19.5556H16.5833C17.1203 19.5556 17.5555 19.1203 17.5555 18.5833V13.2361C17.5555 12.6992 17.9908 12.2639 18.5278 12.2639C19.0647 12.2639 19.5 12.6992 19.5 13.2361V18.5833C19.5 20.1942 18.1942 21.5 16.5833 21.5H4.91668C3.30584 21.5 2 20.1942 2 18.5833V6.91667C2 5.30584 3.30584 4 4.91668 4H10.2639C10.8008 4 11.2361 4.43528 11.2361 4.97222C11.2361 5.50917 10.8008 5.94444 10.2639 5.94444H4.91668Z"
      fill="currentColor"
    />
    <path
      d="M19.414 2.51065C18.7331 1.82979 17.6292 1.82978 16.9484 2.51064L15.2008 4.25826L19.2418 8.29926L20.9894 6.55165C21.6702 5.8708 21.6702 4.7669 20.9894 4.08604L19.414 2.51065Z"
      fill="currentColor"
    />
    <path
      d="M18.1811 9.35992L14.1401 5.31892L8.60228 10.8567C8.25152 11.2075 8.00269 11.647 7.88238 12.1282L7.02241 15.5681C6.95851 15.8237 7.0334 16.094 7.21969 16.2803C7.40597 16.4666 7.67634 16.5415 7.93192 16.4776L11.3718 15.6176C11.853 15.4973 12.2925 15.2485 12.6433 14.8977L18.1811 9.35992Z"
      fill="currentColor"
    />
  </svg>
);

export default PencilEdit02Icon;
