import { Link } from "react-router-dom";
import { CustomAnimatedTooltip } from "./Custom-Animated-Tooltip";

interface NavBarProps {
  onClickClose: () => void;
}

const NavBar: React.FC<NavBarProps> = ({ onClickClose }) => {
  const emtpyFunc = () => {};
  const navItems: {
    id: number;
    name: string;
    role: string;
    link: string;
    imgSrc: string;
    onClick: () => void;
  }[] = [
    {
      id: 0,
      name: "Home",
      role: "Bekijk de nieuwste advertenties",
      link: "/home",
      imgSrc: "/icons/home_button.svg",
      onClick: emtpyFunc,
    },
    {
      id: 1,
      name: "Sell",
      role: "Nieuwe advertentie plaatsen",
      link: "/sell",
      imgSrc: "/icons/add_button.svg",
      onClick: emtpyFunc,
    },
    {
      id: 2,
      name: "Mijn paarden",
      role: "Bekijk al uw advertenties",
      link: "/my-horses",
      imgSrc: "/icons/my_horses_solid.svg",
      onClick: onClickClose,
    },
  ];

  return (
    <>
      <div className="floating-button-container z-50 justify-between  rounded-full ">
        <CustomAnimatedTooltip items={navItems} />

        {/* <div className="">

          <Link to="/home">
            <div
              className="flex flex-col items-center floating-button-icon p-2 bg-[#10393b] mr-5 hover:bg-[#F0663E] transition-all duration-200 ease-in-out"
              title="Home"
            >
              <img className="h-10" src="/icons/home_button.svg" alt="" />
              <p className="text-white font-semibold text-sm"></p>
            </div>
          </Link>
        </div>
        <div className="">
          <Link to="/sell">
            <div
              className="flex flex-col items-center  mr-5 floating-button-icon bg-[#10393b] p-2 hover:bg-[#F0663E] transition-all duration-200 ease-in-out "
              title="Sell"
            >
              <img className=" h-10" src="/icons/add_button.svg" alt="" />
              <p className="text-white font-semibold text-sm"></p>
            </div>
          </Link>
        </div>
        <div className="">
          <Link to="/sell">
            <div
              className="flex flex-col items-center  floating-button-icon bg-[#10393b] p-2 hover:bg-[#F0663E] transition-all duration-200 ease-in-out "
              title="Mijn paarden"
            >
              <img className=" h-10" src="/icons/my_horses_solid.svg" alt="" />
              <p className="text-white font-semibold text-sm text-center"></p>
            </div>
          </Link>
        </div> */}
      </div>
    </>
  );
};

export default NavBar;
