import React, { RefObject, useEffect } from "react";

interface TextInputProps {
  isValid: boolean;
  disabled?: boolean;
  label: string;
  placeholder: string;
  id: string;
  name: string;
  maxLength?: number;
  inputRef: RefObject<HTMLInputElement>;
  type: string;
  className?: string;
  errorMessage?: string;
  handleChange: () => void;
  onFocusChange?: () => void;
}

export const CustomTextInput: React.FC<TextInputProps> = ({
  isValid,
  disabled = false,
  label,
  placeholder,
  id,
  name,
  maxLength,
  inputRef,
  type,
  className,
  errorMessage = "",
  handleChange,
  onFocusChange = () => {},
}) => {
  useEffect(() => {}, [isValid]);
  return (
    <div className={`${className} form-row my-6 `}>
      <p className="form-element flex flex-col">
        <label
          className="font-semibold text-lg md:text-xl mb-2 ml-3"
          htmlFor={id}
        >
          {label}
        </label>
        <input
          className={
            isValid
              ? "form-input-element"
              : "form-input-element form-field-error"
          }
          maxLength={maxLength}
          type={type}
          name={name}
          placeholder={placeholder}
          id={id}
          ref={inputRef}
          onChange={handleChange}
          onBlur={onFocusChange}
          disabled={disabled}
          required
        />
        <InputFieldErrorMessage message={errorMessage} isValid={isValid} />
      </p>
    </div>
  );
};

interface InputFieldErrorMessageProps {
  message: string;
  isValid: boolean;
}

export const InputFieldErrorMessage: React.FC<InputFieldErrorMessageProps> = ({
  isValid,
  message,
}) => {
  if (!isValid) {
    return <p className="text-[#F0663E] text-base ml-2 pt-1">{message}</p>;
  } else {
    return <></>;
  }
};
