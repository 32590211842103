import React, { RefObject } from "react";
import { InputFieldErrorMessage } from "./CustomTextInput";

interface TextInputProps {
  isValid: boolean;
  label: string;
  placeholder: string;
  id: string;
  name: string;
  maxLength: number;
  inputTextAreaRef: RefObject<HTMLTextAreaElement>;
  errorMessage?: string;
  handleChange: () => void;
}

export const CustomTextAreaInput: React.FC<TextInputProps> = ({
  isValid,
  label,
  placeholder,
  id,
  name,
  maxLength,
  inputTextAreaRef,
  errorMessage = "",
  handleChange,
}) => {
  return (
    <div className="form-row">
      <p className="form-element flex flex-col">
        <label className="font-semibold text-xl mb-2 ml-3" htmlFor={id}>
          {label}
        </label>

        <textarea
          className={
            isValid
              ? "form-input-element resize-y"
              : "form-input-element form-field-error resize-y"
          }
          maxLength={maxLength}
          name={name}
          placeholder={placeholder}
          id={id}
          ref={inputTextAreaRef}
          onChange={handleChange}
          required
          rows={4} // Initial height for the textarea
        />
        <InputFieldErrorMessage message={errorMessage} isValid={isValid} />
      </p>
    </div>
  );
};
