export interface AlertDialogConfigs {
  isOpen: boolean;
  title: string;
  message: string;
  secondaryBtnText: string;
  primaryBtnText: string;
  primaryBtnAction: () => Promise<void>;
}
export interface AlertDialogConfigsNoAsync {
  isOpen: boolean;
  title: string;
  message: string;
  secondaryBtnText: string;
  primaryBtnText: string;
  primaryBtnAction: () => void;
}

const snackBarConfigType: {
  open: boolean;
  severity: "success" | "error" | "warning" | "info";
  message: string;
} = { open: false, severity: "success", message: "" };

export { snackBarConfigType };
